.bannerabus li:before {
    content: "\f00c";
    font-family: "FontAwesome";
    color: #5ca9fb;
    font-size: 1.5rem;
    font-weight: 300;
    padding-right: 8px;
  }


  .bannerabus {
    color: #000;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: system-ui, sans-serif;
    font-size: 1.8rem;
    line-height: 1.5;

 
  }

.abus-list-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15rem;
}


@media (max-width: 767.98px) {
    .abus-list-cont {

        gap: 2rem;
    }
    .bannerabus {
        font-size: 1.7rem;
    }

}