.logo-box {
    width: 120%;
}

.col-md-12-col-md-6 {
    display: flex;
    justify-content: center;
}

@media (max-width: 767.98px) {
    .main-comp-logo {
        display: block;
        justify-content: center;
        align-items: center;

    }

    .logos-container {
        margin: 0 auto;
        padding-top: 3.5rem;
        width: 80%;
    }

    .logo-box {
        width: 100%;
    }
}