.key-benefits-item p {
    font-size: 16px;
    line-height: 1.6;
    color: #666;
}

.key-benefits-item h3 {
    font-size: 22px;
    color: #31732f;
    margin: 0;
}


.key-benefits-item p {
    font-size: 1.8rem;
    color: #666;
    margin: 0;
    padding-bottom: 3rem;
}

.key-benefits h2 {
    font-size: 42px;
    font-weight: bold;
    color: #333;
    margin-bottom: 30px;
    position: relative;
    padding-bottom: 15px;
    padding-left: 2rem;
}

.key-benefits h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 3px;
    background: linear-gradient(to right, #31792f, #3a6f36);
}

.key-benefits {
    border-bottom: 4px solid #31792f;
}

.key-benefits p {
    font-size: 1.8rem;
    line-height: 1.8;
    color: #555;
    margin-bottom: 30px;
}

.consultation-button {
        background: #31792f;
        color: #ffffff;
        padding: 15px 30px;
        border: none;
        border-radius: 4px;
        font-size: 18px;
        font-weight: 600;
        cursor: pointer;
        transition: background-color 0.3s ease;
        text-transform: uppercase;
        letter-spacing: 1px;
}

.second-paragraph {
    display: flex
;
    flex-direction: column;
    align-items: left;
}
.second-paragraph p {
    font-size: 18px;
    line-height: 1.8;
    color: #555;
    margin-bottom: 30px;

}

.intro-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 3rem;
    padding-bottom: 3rem;
    display: flex
;
    align-items: stretch;
    gap: 60px;
}

@media (max-width: 768px) {
    .intro-wrapper {
    display: block;
    }

  }
.content-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.consultation-button-container {
    display: flex;
    justify-content: left;
}

.first-paragraph {
    flex: 1 1;
    display: flex; 
    align-items: center; 
}

.first-paragraph p {
    font-size: 18px;
    line-height: 1.8;
    color: #555;
    margin-bottom: 30px;
}

.expect-p {
    font-size: 1.8rem;
    line-height: 1.8;
    color: #555;
}