/* Reset and Base Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Page Layout */
.rack-inspection-page {
  width: 100%;
  background-color: #ffffff;
}

/* Hero Section */
.hero-section {
  padding: 80px 0;
  background-color: #ffffff;
}

.hero-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  gap: 60px;
}

.hero-text {
  flex: 1;
}

.hero-text h1 {
  font-size: 48px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.hero-text h2 {
  font-size: 24px;
  color: #31792f;
  margin-bottom: 20px;
}

.hero-text p {
  font-size: 18px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 30px;
}

.hero-image {
  flex: 1;
}

.hero-image img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

/* Title Section */
.title-section {
  padding-top: 140px;
  padding-bottom: 30px;
  text-align: center;
  background-color: #ffffff;
  position: relative;
}

.title-section h1 {
  font-size: 48px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.title-section h2 {
  font-size: 28px;
  color: #31792f;
  font-weight: 500;
  position: relative;
  display: inline-block;
  padding-bottom: 20px;
}

.title-section h2:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background: linear-gradient(to right, #31792f, #3a6f36);
}

/* Content Section with Image */
.content-section {
  padding: 30px 0;
  background-color: #ffffff;
}

.content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px;
  display: flex;
  align-items: stretch;
  gap: 60px;
}

.content-text {
  flex: 1;
}

.content-text p {
  font-size: 18px;
  line-height: 1.8;
  color: #555;
  margin-bottom: 30px;
}

.content-image {
  flex: 1;

  justify-content: center;
  align-items: center;

}

.content-image img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.content-image2 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-image2 img {
  width: 100%;
  height: 400px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.content-image3 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}


.content-image3 img {
  background-color: #fff;
  width: 70%;
  height: 400px;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 180px;
  object-fit: contain;
 
}

.content-image3desktop {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}


.content-image3desktop img {
  background-color: #fff;
  width: 70%;
  height: 400px;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 180px;
  object-fit: contain;
 
}

.content-image3mobile {
  flex: 1;
  display: none;
  justify-content: center;
  align-items: center;
}

.content-image3mobile img {
  background-color: #fff;
  width: 70%;
  height: 400px;
  padding: 30px;
  border-radius: 10px;
  display: none;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 180px;
  object-fit: contain;
}


@media (max-width: 768px) {
  .content-image3mobile {
    display: flex;
  }
  
  .content-image3desktop {
    display : none;
  }
  

  .content-image3mobile img {
    background-color: #fff;
    width: 70%;
    height: 400px;
    padding: 30px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 180px;
    object-fit: contain;
  }
}

.content-image6 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4rem;
}


.content-image6 img {
  background-color: #fff;

  height: 400px;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  height: 250px;
  object-fit: contain;
 
}

@media (max-width: 768px) {
  .content-image6 img {
    width: 100%;
    height: auto;
  }
}
.content-image7 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4rem;
}

.content-image7 img {
  background-color: #fff;

  height: 400px;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  height: 250px;
  object-fit: contain;
 
}

.content-image8 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4rem;
}


.content-image8 img {
  background-color: #fff;

  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  height: 200px;
  object-fit: contain;
 
}

.content-image4 {

  justify-content: center;
  align-items: center;
}

.content-image4 img {

  height: 60rem;
  border-radius: 4px;
  transition: transform 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


/* What to Expect Section - New Styles */
.expect-section {
  padding: 40px 0;
  background-color: #f8f9fa;
  border-top: 1px solid #eee;
}

.expect-content {
  max-width: 1200px;
  margin: 0 auto;
}

.expect-content h2 {
  font-size: 42px;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 15px;
}

.expect-content h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background: linear-gradient(to right, #31792f, #3a6f36);
}

.expect-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-top: 30px;
}

.expect-item {
  position: relative;
  padding: 30px;
  background: #ffffff;
  border-left: 4px solid #31792f;
}

.expect-number {
  font-size: 48px;
  font-weight: 800;
  color: #f0f0f0;
  position: absolute;
  top: 20px;
  right: 20px;
}

.expect-item h3 {
  font-size: 24px;
  color: #333;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
}

.expect-item p {
  font-size: 16px;
  line-height: 1.6;
  color: #666;
  position: relative;
  z-index: 1;
}

/* Why Choose Us Section - New Styles */
.choose-us-section {
  padding: 40px 0;
  background: #ffffff;
}

.choose-content h2 {
  font-size: 42px;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 15px;
}

.choose-content h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background: linear-gradient(to right, #31792f, #3a6f36);
}

.choose-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  margin-top: 30px;
}

.choose-item {
  padding: 30px;
  background: #f8f9fa;
  border-bottom: 4px solid #31792f;
  transition: transform 0.3s ease;
}

.choose-item:hover {
  transform: translateY(-5px);
}

.choose-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.choose-icon {
  font-size: 24px;
  margin-right: 15px;
}

.choose-item h3 {
  font-size: 22px;
  color: #333;
  margin: 0;
}

.choose-item p {
  font-size: 16px;
  line-height: 1.6;
  color: #666;
}

@media (max-width: 768px) {
  .expect-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .choose-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .expect-content h2,
  .choose-content h2 {
    font-size: 32px;
  }

  .expect-item,
  .choose-item {
    padding: 20px;
  }
}

/* Button Styles */
.btn-primary, .btn-green {
  background: #31792f;
  color: #ffffff;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease;

}

.btn-primary:hover, .btn-green:hover {
  background: #3a6f36;
}

/* FAQ Section */
.faq-section {
  padding: 40px 0;
  background-color: #f8f9fa;
  border-top: 1px solid #eee;
}

.faq-section h2 {
  text-align: center;
  margin-bottom: 30px;
}

.faq-item {
  margin-bottom: 15px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.faq-item:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.faq-question {
  width: 100%;
  padding: 20px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  transition: all 0.3s ease;
}

.faq-question span:first-child {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  flex: 1;
  padding-right: 20px;
}

.faq-arrow {
  color: #31792f;
  font-size: 14px;
  transition: transform 0.3s ease;
}

.faq-question[aria-expanded="true"] .faq-arrow {
  transform: rotate(180deg);
}

.faq-answer {
  padding: 0 25px 20px 25px;
  font-size: 16px;
  line-height: 1.8;
  color: #666;
  background-color: #fff;
  border-top: 1px solid #f0f0f0;
  animation: slideDown 0.3s ease-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Add hover effect to questions */
.faq-question:hover span:first-child {
  color: #31792f;
}

/* Active state for questions */
.faq-question:focus {
  outline: none;
  background-color: #f8f9fa;
}

/* Container for FAQ items */
.faq-section .section-container {
  max-width: 900px; /* Slightly narrower for better readability */
  margin: 0 auto;
}

/* Section Spacing */
.expect-section,
.choose-us-section,
.faq-section,
.practical-training-section {
  margin-top: 0;
}

.section-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .title-section {
    padding-top: 120px;
  }

  .title-section h1 {
    font-size: 36px;
  }

  .title-section h2 {
    font-size: 24px;
  }

  .content-wrapper {
    flex-direction: column;
    gap: 40px;
  }

  .expect-section h2,
  .choose-us-section h2 {
    font-size: 32px;
  }
}

/* Practical Training Section - New Styles */
.practical-training-section {
  padding: 80px 0;
  background-color: white;
  border-top: 1px solid #eee;
}

.training-content {
  max-width: 900px;
  margin: 0 auto;
}

.training-text h2 {
  font-size: 42px;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 15px;
}

.training-text h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background: linear-gradient(to right, #31792f, #3a6f36);
}

.training-details {
  margin-top: 30px;
}

.training-details p {
  font-size: 18px;
  line-height: 1.8;
  color: #555;
  margin-bottom: 25px;
}

.training-details ul {
  list-style: none;
  padding: 0;
  margin: 30px 0;
}

.training-details li {
  font-size: 18px;
  line-height: 1.8;
  color: #555;
  padding-left: 30px;
  margin-bottom: 15px;
  position: relative;
}

.training-details li::before {
  content: '✓';
  position: absolute;
  left: 0;
  color: #31792f;
  font-weight: bold;
}

.training-note {
  margin-top: 30px;
  padding: 25px;
  background: #ffffff;
  border-left: 4px solid #31792f;
}

.note-highlight {
  display: block;
  font-size: 20px;
  font-weight: 600;
  color: #31792f;
  margin-bottom: 10px;
}

.training-note p {
  margin: 0;
  font-size: 16px;
  color: #666;
}

.training-cta {
  margin-top: 40px;
  text-align: center;
}

.btn-training {
  display: inline-flex;
  align-items: center;
  background: #31792f;
  color: #ffffff;
  padding: 15px 30px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
}

.btn-training:hover {
  background: #3a6f36;
  color: #ffffff;
  text-decoration: none;
}

.arrow-icon {
  margin-left: 10px;
  font-size: 20px;
  transition: transform 0.3s ease;
}

.btn-training:hover .arrow-icon {
  transform: translateX(5px);
}

@media (max-width: 768px) {
  .practical-training-section {
    padding: 60px 0;
  }

  .training-text h2 {
    font-size: 32px;
  }

  .training-note {
    padding: 20px;
  }
}

/* Training Grid */
/* Training Grid */
.training-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Four columns */
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.training-card {
  background: #fff;
  padding: 30px;
  border-radius: 0px;
  border: 1px solid #31792f;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
}

.training-card-content {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: flex-start; /* Align items to the start */
  gap: 10px; /* Space between elements */
  flex: 1;
}

.training-card-content h3 {
  font-size: 20px;
  color: #333;
}

.price-tag {
  font-size: 18px;
  color: #394d39;
}

.includes {
  font-size: 16px;
  color: #666;
  flex: 1;
}

.btn-training {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #31792f;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  margin-top: auto;
}

.btn-training:hover {
  background-color: #275a23;
}

.arrow-icon {
  margin-left: 10px;
}

@media (max-width: 768px) {
  .training-card-content {
    flex-direction: column;
    text-align: center;
  }
  
  .training-card-content h3,
  .price-tag,
  .includes {
    flex: none;
    margin-bottom: 15px;
  }
  .training-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* Four columns */
    gap: 30px;
    max-width: 300px;
    margin: 0 auto;
  }

  .content-image2 img {

      width: 100%;
      height: auto;
    }

    .fst-training-txt {
      display: block;
    }

  }


.training-card {
  background: #fff;
  padding: 30px;
  border-radius: 0px;
  border: 1px solid #31792f;
  transition: transform 0.3s ease;
}

.training-card-content {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: flex-start; /* Align items to the start */
  gap: 10px; /* Space between elements */
}

.training-card-content h3 {
  font-size:2rem;
  color: #333;
}

.price-tag {
  font-size: 3rem;
  color: #31792f;
  min-height: 10rem;
  font-weight: bold;
}

.includes {
  font-size: 16px;
  color: #666;
}

.training-card-header {
  min-height: 12rem;
}
.btn-training {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #31792f;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.btn-training:hover {
  background-color: #275a23;
}

.arrow-icon {
  margin-left: 10px;
}

@media (max-width: 768px) {
  .training-card-content {
    flex-direction: column;
    text-align: center;
  }
  
  .training-card-content h3,
  .price-tag,
  .includes {
    flex: none;
    margin-bottom: 15px;
  }
}

/* Benefits List Styling */
.benefits-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.benefits-list li {
  margin-bottom: 20px;
  padding-left: 25px;
  position: relative;
}

.benefits-list li:before {
  content: "•";
  color: #31792f;
  font-weight: bold;
  position: absolute;
  left: 0;
}

.benefits-list li strong {
  color: #31792f;
  display: block;
  margin-bottom: 5px;
}

/* About Page Specific Spacing */
.about-page .expect-section {
  background-color: #ffffff;
}

.about-page .expect-grid {
  grid-template-columns: repeat(2, 1fr);
}

.about-page .expect-item {
  padding: 30px;
}

@media (max-width: 768px) {
  .about-page .expect-grid {
    grid-template-columns: 1fr;
  }
  
  .benefits-list li {
    margin-bottom: 15px;
  }
}

/* Solution Timeline Styling */
.solution-timeline {
  position: relative;
  padding: 20px 0;
}

.timeline-item {
  display: flex;
  margin-bottom: 30px;
  position: relative;
}

.timeline-item:last-child {
  margin-bottom: 0;
}

.timeline-content {
  flex: 1;
  margin-left: 30px;
  background: #ffffff;
  padding: 25px;
  border-radius: 8px;
  border: 1px solid #eee;
  transition: transform 0.3s ease;
}

.timeline-content:hover {
  transform: translateY(-5px);
}

.timeline-content h3 {
  color: #31792f;
  margin-bottom: 15px;
}

.timeline-content p {
  margin-bottom: 20px;
  color: #666;
}

.timeline-content .btn-primary {
  display: inline-block;
}

.training-details-section {
  background: #f8f9fa;
  padding: 4rem 0;
}

.details-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.details-card {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.details-card h3 {
  color: #007bff;
  margin-bottom: 1rem;
}

.course-content-section {
  padding: 4rem 0;
}

.content-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.content-item {
  padding: 2rem;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.content-item:hover {
  transform: translateY(-5px);
}

.additional-info-section {
  background: #f8f9fa;
  padding: 4rem 0;
}

.info-content {
  max-width: 800px;
  margin: 0 auto;
}

.info-content ul {
  margin-top: 2rem;
  padding-left: 2rem;
}

.info-content li {
  margin-bottom: 1rem;
}

.contact-section {
  padding: 4rem 0;
  text-align: center;
}

.btn-primary {
  margin-top: 2rem;
}



@media (max-width: 768px) {
  .timeline-item {
    flex-direction: column;
  }
  
  .timeline-content {
    margin-left: 0;
    margin-top: 15px;
  }
}

/* Key Points Section */
.key-points-section {
  padding: 40px 0;  /* Matching your existing section padding */
  background: #f8f9fa;
}

.key-points {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 1200px;  /* Matching your existing container width */
  margin: 0 auto;
  padding: 0 20px;
}

.point {
  padding: 30px;  /* Matching your existing card padding */
  background: white;
  border-radius: 8px;
  border-left: 4px solid #31792f;  /* Matching your brand color */
  transition: transform 0.3s ease;
}

.point:hover {
  transform: translateY(-5px);  /* Matching your existing hover effects */
}

.point h3 {
  font-size: 24px;  /* Matching your existing heading sizes */
  color: #333;
  margin-bottom: 1rem;
}

.point ul {
  list-style-type: none;
  padding: 0;
}

.point ul li {
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;
  font-size: 16px;
  line-height: 1.6;
  color: #666;
}

.point ul li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: #31792f;  /* Matching your brand color */
}

@media (min-width: 768px) {
  .key-points {
    flex-direction: row;
  }
  
  .point {
    flex: 1;
  }
}

/* Price Banner */
.price-banner {
  font-size: 36px;
  font-weight: bold;
  color: #31792f;
  margin: 30px 0;
  padding: 20px 40px;
  background: #f8f9fa;
  display: inline-block;
  border-radius: 8px;
  border: 3px solid #31792f;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Course Topics Section */
.course-topics {
  background: #f8f9fa;
  padding: 30px;
  border-radius: 8px;
  border-left: 4px solid #31792f;
}

.topic-intro {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  margin-bottom: 25px;
}

.topics-list {
  list-style: none;
  padding: 0;
}

.topics-list li {
  margin-bottom: 15px;
  padding-left: 25px;
  position: relative;
  line-height: 1.6;
  color: #555;
}

.topics-list li:before {
  content: "•";
  color: #31792f;
  position: absolute;
  left: 0;
  font-weight: bold;
}

.certification-note {
  margin-top: 25px;
  font-weight: 500;
  color: #31792f;
  font-style: italic;
}

/* Info Columns */
.info-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.info-column p {
  font-size: 16px;
  line-height: 1.8;
  color: #555;
}

@media (max-width: 768px) {
  .price-banner {
    font-size: 28px;
    padding: 15px 30px;
  }

  .info-columns {
    grid-template-columns: 1fr;
    gap: 30px;
    padding: 30px 20px;
  }

  .content-wrapper {
    flex-direction: column;
  }
}

.price-card {
  background: #f8f9fa;
  padding: 40px;
  border-radius: 8px;
  border-left: 4px solid #31792f;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.price-amount {
  font-size: 48px;
  font-weight: bold;
  color: #31792f;
  margin-bottom: 10px;
}

.price-detail {
  font-size: 20px;
  color: #666;
  margin-bottom: 30px;
}

.training-details {
  margin: 30px 0;
}

.detail-item {
  margin-bottom: 20px;
  text-align: left;
}

.detail-item h3 {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}

.detail-item p {
  font-size: 18px;
  color: #666;
  margin: 0;
}

.course-topics {
  background: #f8f9fa;
  padding: 40px;
  border-radius: 8px;
  border-left: 4px solid #31792f;
  height: 100%;
}

.course-topics h3 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.topic-intro {
  font-size: 18px;
  color: #666;
  margin-bottom: 25px;
}

.topics-list {
  list-style: none;
  padding: 0;
}

.topics-list li {
  font-size: 18px;
  margin-bottom: 15px;
  padding-left: 25px;
  position: relative;
  line-height: 1.6;
  color: #555;
}

.topics-list li:before {
  content: "•";
  color: #31792f;
  position: absolute;
  left: 0;
  font-weight: bold;
}

@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
  }
  
  .price-card, .course-topics {
    height: auto;
  }
}

  
  
  