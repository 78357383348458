.file-button {
    cursor: pointer;
    display: inline-block;
    font-size: 1.5em;
    margin: 0;
    text-align: center;
    text-decoration: none;
}
.please-fill-out {
    font-size: 1.6rem;
}

.translogo {
    width: 8rem;
    margin-bottom:1.2rem;

    border: 2px solid #fff;
    border-radius: 50px;
}

.footerbuttons {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
}