.why-capacity-text {
    font-size: 1.8rem;
    color: #666;
  }
  
  .why-capacity-list ul {
    padding-left: 0px; 
  }
  
  .why-capacity-list ul li {
    font-size: 1.6rem; /* Ensure consistent font size */
    line-height: 1.5; /* Optional: Adjust line height for better readability */
  }
  
  /* .why-capacity-list li:before {
    content: "\f00c";
    font-family: "FontAwesome";
    color: #5ca9fb;
    font-size: 1.5rem;
    font-weight: 300;
    padding-right: 8px;
  } */

  .why-capacity-list h3:before {
    content: "\f00c";
    font-family: "FontAwesome";
    color: #5ca9fb;
    font-size: 1.5rem;
    font-weight: 300;
    padding-right: 1.8rem;
  }

  .why-capacity-list h3 {
    color: #31732f;
    font-size: 2.4rem;
  }

h2,
h3,
h4 {
  font-family: "Raleway", sans-serif;
}

.to-expect-num {
    font-size: 48px;
    font-weight: 800;
    color: #cacaca;
    padding-right: 4rem;
    
    /* position: absolute;
    top: 20px;
    left: 20px; */
}

.expect-num-list-item {
    display: flex;
    align-items: center;
}

.expect-num-list-item h3{
    font-size: 24px;
    color: #31732f;
    margin-bottom: 15px;
    position: relative;
    z-index: 1;
}

.expect-num-list p {
    font-size: 1.8rem;
    line-height: 1.6;
    color: #666;
    position: relative;
    z-index: 1;
    padding-bottom: 2rem;
}

.inline_choose_content p {
    font-size: 1.8rem;
    line-height: 1.6;
    color: #666;
    padding-bottom: 0rem;
}