@media screen and (max-width: 768px) {
  #features,
  #about,
  #services,
  #contact,
  #footer,
  #stats-banner,
  .intro {
    width: 100%;
    overflow-x: hidden;
  }

  .container {
    max-width: 100%;
    overflow-x: hidden;
  }

  body {
    overflow-x: hidden;
    width: 100%;
  }
}