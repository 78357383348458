body {
  background-color: white;
}


.content-text {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
}

.quoteButton {
  margin-bottom: 50px; /* Add some space above the button if needed */
}

.expect-content h2 {
  padding-left: 2rem; /* Add some space to the left of the text */
}

.expect-content h2::after {
  left: 2rem; 
}

.expect-row1 {    
  display: flex;
  padding-bottom: 2rem;
}

.expect-row2 {
  display: flex;
  padding-top: 2rem;

}


.expect-text-left {
  width: 50%;
  padding-right: 30px; /* Add some space to the right of the text */
  padding-left: 30px;
}

.expect-text-left h3 {
  font-size: 3.2rem;
  padding-bottom: 1rem;
}

.expect-text-right {
  width: 50%;
  padding-right: 30px; /* Add some space to the right of the text */
  padding-left: 30px;
  min-height: 27rem;

}

.expect-text-right h3 {
  font-size: 3.2rem;
  padding-bottom: 1rem;
}

.expect-images {
  flex: 1 1 50%; /* Takes 50% of the parent's width */
  height: 100%; /* Matches the height of the parent */
  overflow: hidden; /* Ensures no extra content spills out */
}

.expect-image-1 {
  width: 100%;
  padding-right: 30px; 
  padding-left: 30px; 
  object-fit: cover;
  height: 32rem;
}

.expect-image-2 {
  width: 100%;
  height: 40rem; /* Increase the height as needed */
  object-fit: cover; /* Maintain aspect ratio */
  padding-right: 30px;
  padding-left: 30px;
}



.why-choose-text {
  font-size: 1.6rem;
}

.why-choose-list ul {
  padding-left: 0px; 
}

.why-choose-list ul li {
  font-size: 1.6rem; /* Ensure consistent font size */
  line-height: 1.5; /* Optional: Adjust line height for better readability */
}

.why-choose-list li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #5ca9fb;
  font-size: 1.5rem;
  font-weight: 300;
  padding-right: 8px;
}

@media (max-width: 768px) {
  .expect-images {
    display: none;
  }

  .expect-text-left {
    width: 100%;
    padding-right: 3rem;
    padding-left: 3rem;
    padding-bottom: 1.5rem;;
  }

  .expect-text-right {
    width: 100%;
    padding-right: 3rem;
    padding-left: 3rem;
    padding-bottom: 1.5rem;
    background-color: #387c34;
    color: white; 
  }
  
  .expect-text-right h3{
   color: white;
   font-size: 2.5rem;
  }

  .expect-text-left h3{
    font-size: 2.5rem;
   }


}

.training-cta {
  width: fit-content;
  padding-right: 3rem;

}

.op-st-button-container {
  display: flex;
  justify-content: right;
}

.expect-section {
  background-color: #387c34;
}


#section-homemade-template h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #31792f 0%, #3a6f36 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 0;
}

#section-homemade-template h2 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

#section-homemade-template .about-text li {
  margin-bottom: 6px;
  margin-left: 6px;
  list-style: none;
  padding: 0;
  font-size: 1.8rem;
}

#section-homemade-template .about-text li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #5ca9fb;
  font-size: 11px;
  font-weight: 300;
  padding-right: 8px;
}

#section-homemade-template-gr h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #31792f 0%, #3a6f36 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 0;
}

#section-homemade-template-gr h2 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

#section-homemade-template-gr .about-text li {
  margin-bottom: 6px;
  margin-left: 6px;
  list-style: none;
  padding: 0;
  font-size: 1.8rem;
}

#section-homemade-template-gr .about-text li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #5ca9fb;
  font-size: 11px;
  font-weight: 300;
  padding-right: 8px;
}

#section-homemade-template {
  padding-top: 3rem;
  padding-bottom: 3rem;
  min-height: 60rem;
  display: flex;
  align-items: center;
}

#section-homemade-template-gr {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background: #e6f7e6;
  min-height: 60rem;
  display: flex;
  align-items: center;
}

#section-solution-template h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #31792f 0%, #3a6f36 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 0;
}

#section-solution-template h2 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

#section-solution-template .about-text li {
  margin-bottom: 6px;
  margin-left: 6px;
  list-style: none;
  padding: 0;
  font-size: 1.8rem;
}

#section-solution-template .about-text li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #5ca9fb;
  font-size: 11px;
  font-weight: 300;
  padding-right: 8px;
}

#section-solution-template-gr h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #31792f 0%, #3a6f36 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 0;
}

#section-solution-template-gr h2 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

#section-solution-template-gr .about-text li {
  margin-bottom: 6px;
  margin-left: 6px;
  list-style: none;
  padding: 0;
  font-size: 1.8rem;
}

#section-solution-template-gr .about-text li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #5ca9fb;
  font-size: 11px;
  font-weight: 300;
  padding-right: 8px;
}

#section-solution-template {

  min-height: 10rem;
  display: flex;
  align-items: center;
}

#section-solution-template-gr {

  background: #e6f7e6;
  min-height: 10rem;
  display: flex;
  align-items: center;
}


#section-homemade-template-grsmall h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #31792f 0%, #3a6f36 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 0;
}

#section-homemade-template-grsmall h2 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

#section-homemade-template-grsmall .about-text li {
  margin-bottom: 6px;
  margin-left: 6px;
  list-style: none;
  padding: 0;
  font-size: 1.8rem;
}

#section-homemade-template-grsmall .about-text li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #5ca9fb;
  font-size: 11px;
  font-weight: 300;
  padding-right: 8px;
}

#section-homemade-template-grsmall {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background: #e6f7e6;
  /* min-height: 60rem; */
  display: flex;
  align-items: center;
}

#section-solution-template-grsmall h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #31792f 0%, #3a6f36 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 0;
}

#section-solution-template-grsmall h2 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

#section-solution-template-grsmall .about-text li {
  margin-bottom: 6px;
  margin-left: 6px;
  list-style: none;
  padding: 0;
  font-size: 1.8rem;
}

#section-solution-template-grsmall .about-text li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #5ca9fb;
  font-size: 11px;
  font-weight: 300;
  padding-right: 8px;
}

#section-solution-template-grsmall {

  background: #e6f7e6;
  min-height: 10rem;
  display: flex;
  align-items: center;
}