/* General Page Styles */
.solution-page {
  padding: 40px 20px;
  background-color: #f9f9f9;
}

.section-title {
  text-align: center;
  margin-bottom: 40px;
}

.main-title {
  font-size: 48px;
  font-weight: bold;
}

.subtitle {
  font-size: 24px;
  color: #555;
  margin-bottom: 20px;
}

p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Solution Steps Layout */
.solution-steps {
  margin: 50px 0;
  position: relative;
}

.solution-step {
  display: flex;
  margin-bottom: 40px;
  align-items: flex-start;
}

.solution-step:nth-child(even) {
  flex-direction: row-reverse;
}

.solution-step-content {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 60%;
  transition: transform 0.3s, box-shadow 0.3s;
}

.solution-step-content:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.solution-step-content h3 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 15px;
}

.solution-step-content p {
  font-size: 18px;
  margin-bottom: 20px;
}

.solution-step-marker {
  position: relative;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.solution-step-marker::before {
  content: '';
  position: absolute;
  width: 4px;
  height: 100%;
  background-color: #31792f;
}

.solution-step-marker::after {
  content: '';
  position: relative;
  width: 20px;
  height: 20px;
  background-color: #31792f;
  border-radius: 50%;
  z-index: 1;
}

.solution-step:nth-child(even) .solution-step-marker::before {
  left: auto;
  right: 50%;
}

.solution-step:nth-child(odd) .solution-step-marker::before {
  left: 50%;
}

.solution-step:nth-child(even) .solution-step-marker::after {
  left: auto;
  right: 50%;
}

.solution-step:nth-child(odd) .solution-step-marker::after {
  left: 50%;
}

.solution-step:last-child .solution-step-marker::before {
  height: 50%;
}

/* Button Styles */
.btn {
  padding: 12px 24px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: transform 0.3s;
}

.btn:hover {
  transform: scale(1.05);
}

.btn-neutral {
  background-color: #555;
  color: white;
}

.btn-cta {
  background-color: #31792f;
  color: white;
  font-size: 18px;
  padding: 15px 30px;
}

/* CTA Section */
.cta-section {
  background-color: #e6f2e5;
  color: #31792f;
  text-align: center;
  padding: 40px 20px;
  border-radius: 15px;
  margin-top: 50px;
  margin-bottom : 100px;
}

.cta-section h2 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.cta-section p {
  font-size: 20px;
  margin-bottom: 30px;
}

.cta-section .btn-cta {
  background-color: #31792f;
  color: white;
}


.opst-solution-subheading {
  display: flex;
}