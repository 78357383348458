.expect-num-list p {
    font-size: 1.8rem;
    color: #666;
    margin: 0;
    padding-bottom: 1rem;   
    /* border-bottom: 2px solid #d9d9d9; */
}

.expect-num-list::before {
    content: '';
    display: block;
    width: 40%; /* Adjust the width as needed */
    border-bottom: 2px solid #0a7619;
    padding-top: 1rem;
}

.expect-num-list-final p {
    font-size: 1.8rem;
    color: #666;
    margin: 0;
    padding-bottom: 1rem;   
    /* border-bottom: 2px solid #d9d9d9; */
}

.expect-num-list-final::before {
    content: '';
    display: block;
    width: 40%; /* Adjust the width as needed */
    border-bottom: 2px solid #0a7619;
    padding-top: 1rem;
}



/* .expect-num-list-final::after  {
    content: '';
    display: block;
    width: 100%; 
    border-bottom: 3px solid #0a7619;
    padding-top: 1rem;
} */


.management-approach-item p {
    font-size: 16px;
    line-height: 1.6;
    color: #666;
}

.management-approach-item h3 {
    font-size: 22px;
    color: #31732f;
    margin: 0;
}


.management-approach-item p {
    font-size: 1.8rem;
    color: #666;
    margin: 0;
    padding-bottom: 3rem;
}

.management-approach h2 {
    font-size: 42px;
    font-weight: bold;
    color: #333;
    margin-bottom: 30px;
    position: relative;
    padding-bottom: 15px;
    padding-left: 2rem;
}

.management-approach h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 3px;
    background: linear-gradient(to right, #31792f, #3a6f36);
}

.management-approach {
    border-bottom: 4px solid #31792f;
}

.subheader-management {
    font-size: 4rem;
}