.key-benefits-item p {
    font-size: 16px;
    line-height: 1.6;
    color: #666;
}

.key-benefits-item h3 {
    font-size: 22px;
    color: #31732f;
    margin: 0;
}


.key-benefits-item p {
    font-size: 1.8rem;
    color: #666;
    margin: 0;
    padding-bottom: 3rem;
}

.key-benefits h2 {
    font-size: 42px;
    font-weight: bold;
    color: #333;
    margin-bottom: 30px;
    position: relative;
    padding-bottom: 15px;
    padding-left: 2rem;
}

.key-benefits h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 3px;
    background: linear-gradient(to right, #31792f, #3a6f36);
}

.key-benefits {
    border-bottom: 4px solid #31792f;
}


.design-final-expect::after {
    content: '';
    display: block;
    width: 100%;
    border-bottom: 4px solid #0a7619;
    padding-top: 2rem;
}