.expect-intro {
    /* padding-top: 1rem; */
    padding-bottom: 1.5rem;
    font-size: 1.8rem;
    color: #555;
}

.expect-intro-final {
    padding-bottom: 1.5rem;
    font-size: 1.8rem;
    color: #555;
}

.expect-num-list-quote {
    font-size: 1.8rem;
    color: #666;
    margin: 0;
    padding-top: 4rem;
    padding-bottom: 2rem;
    border-bottom: 2px solid #0a7619;
    font-weight: bold;
}