.row.align-items-stretch {
    display: flex;
  }
  
  @media (max-width: 767.98px) {
    .row.align-items-stretch {
        display: block;
        /* padding-top: 2rem;
        padding-bottom:2rem; */
    }
    .opstsol-mobile {
        padding-top:3rem;
        padding-bottom:3rem;
    }
    
    
  }
.col-12.col-md-6 {
    display: flex;
    justify-content: center;
    align-items: center;  
}

.about-image-container {
    height: 80%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-fluid.about-image {
    height: 40rem;
    width: auto;
    object-fit: cover;
    
}

.img-fluid.about-image2 {

    width: auto;
    object-fit: cover;
    
}

.about-image2 {
    width: 100%;
    height: auto;
    max-width: 520px;
    border-radius: 8px;
    /* display: block; */
    /* margin: 0 auto; */
    /* border-radius: 8px; */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
}

@media (max-width: 767.98px) {
    .about-image-container {
        display: none;
    }

    .row {
        margin-right: 0;
        margin-left: 0;
    }
    
    .quoteButton {
        margin-bottom: 0;
    }

    .training-grid {
        grid-template-columns: repeat(1, 1fr);
    }

}